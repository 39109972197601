import Preparation from "@/models/Preparation";

export default (store) => {
  return async (to, from, next) => {
    const currentMissionUuid = store.getters["missions/currentMission"].uuid;
    const preparation = await store.dispatch(
      "preparation/retrievePreparationByMission",
      currentMissionUuid
    );
    if (preparation) {
      return next();
    }
    const preparationToCreate = new Preparation({
      mission: currentMissionUuid,
    });
    try {
      await store.dispatch(
        "preparation/createPreparation",
        preparationToCreate
      );
      return next();
    } catch (e) {
      console.error(e);
      return next();
    }
  };
};
