import socioGrpcClient from "@/setup/socioGrpcClient";
import Survey from "@/models/Survey";
import { buildMetadata, sspMetadata } from "@/utils/metadata";
import Sample from "@/models/Sample";
import { SAMPLES_TYPES } from "@/constants/samples";
import { renameKeys, responseTojavascript } from "@/utils/utilsGrcpRest";
const SURVEY_FIELDS_TO_EXCLUDE = [
  "$id",
  "analysisData",
  "samples",
  "strainedTubeHeight",
  "columnHeightFromSoilOnImplantationEnd",
  "columnVolumeFromSoil",
  "lithologicalLayers",
];
const SURVEY_KEYS_MAP = {
  fillingChoicesList: "fillingChoices",
};
const state = {
  surveysCount: 0,
};

const mutations = {
  SET_SURVEYS_COUNT: (state, count) => {
    state.surveysCount = count;
  },
};

const getters = {
  surveysCount: (state) => {
    return state.surveysCount;
  },
  surveyFromUuid: () => (uuid) => {
    return Survey.query().find(uuid);
  },
  isSurveyNeedSuperficialSample: () => (surveyUuid) => {
    const isSuperficialSampleExists = Sample.query()
      .where("survey", surveyUuid)
      .where("sampleType", SAMPLES_TYPES.SUPERFICIAL)
      .exists();

    const isFirstSampleUnderZeroExist = Sample.query()
      .where("isFirstSample", true)
      .where("survey", surveyUuid)
      .where("samplingTop", (value) => value !== 0)
      .exists();

    return isFirstSampleUnderZeroExist && !isSuperficialSampleExists;
  },
};

const actions = {
  async fetchSurveys({ commit }, { metadata }) {
    const requestMetadata = {
      ...metadata,
      pagination: {
        page_size: 100,
      },
    };
    const request =
      new socioGrpcClient.ssp_back.intervention.SurveyListRequest();
    const response =
      await socioGrpcClient.ssp_back.intervention.SurveyControllerPromiseClient.list(
        request,
        buildMetadata(requestMetadata)
      );
    const { resultsList, count } = response.toObject();
    commit("SET_SURVEYS_COUNT", count);
    const data = resultsList.map((survey) =>
      renameKeys(SURVEY_KEYS_MAP, survey)
    );
    Survey.create({
      data: data,
    });
  },

  async deleteSurvey({ commit, getters }, { uuid }) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.SurveyDestroyRequest,
      { uuid }
    );
    await socioGrpcClient.ssp_back.intervention.SurveyControllerPromiseClient.destroy(
      request,
      sspMetadata()
    );

    commit("SET_SURVEYS_COUNT", getters.surveysCount - 1);
    const samples = Sample.query().where("survey", uuid).get();
    samples.forEach((sample) => {
      Sample.delete(sample.uuid);
    });
    await Survey.delete(uuid);
  },

  async createSurvey({ commit, getters }, survey) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.SurveyRequest,
      survey,
      SURVEY_FIELDS_TO_EXCLUDE
    );

    const response =
      await socioGrpcClient.ssp_back.intervention.SurveyControllerPromiseClient.create(
        request,
        sspMetadata()
      );
    commit("SET_SURVEYS_COUNT", getters.surveysCount + 1);
    const { data } = responseTojavascript(response, SURVEY_KEYS_MAP);

    await Survey.insert({
      data: data,
    });

    return response.toObject().uuid;
  },

  async updateSurvey(_, survey) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.SurveyRequest,
      survey,
      SURVEY_FIELDS_TO_EXCLUDE
    );
    const response =
      await socioGrpcClient.ssp_back.intervention.SurveyControllerPromiseClient.update(
        request,
        sspMetadata()
      );
    const { data } = responseTojavascript(response, SURVEY_KEYS_MAP);
    await Survey.update({
      where: survey.uuid,
      data: data,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
